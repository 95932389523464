@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@700&family=Raleway:wght@400;700&family=Seaweed+Script&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
main {
  overflow-x: hidden;
}
.App {
  overflow-x: hidden;
}

h1,
h2,
h4 {
  font-family: Cormorant Infant;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h1 {
  font-size: 70px;
}
h2 {
  font-size: 40px;
}
h4 {
  font-size: 20px;
}
h3 {
  font-family: Seaweed Script;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h5 {
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h6 {
}
p {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.main_container2 {
  @apply w-full  px-5 xl:px-7;
}
.main_container1 {
  @apply max-w-[1360px] mx-auto px-5 xl:px-7;
}

.btn1 {
  @apply py-3 px-5 rounded 
    inline-flex justify-center items-center font-bold textarea-sm gap-3 capitalize transition-all ease-in-out duration-500;
}
.btn1:hover {
  @apply transition-all ease-in-out duration-500;
}

.nav_item {
  @apply font-Raleway font-normal 2xl:mr-9 mr-4  relative flex text-center capitalize;
}
.dropdown-content .active {
  @apply text-troo-primary bg-troo-secondary;
}
.nav_link {
  @apply flex items-center font-Raleway text-troo-black h-full font-medium text-sm 2xl:text-base;
}
.nav_link.active {
  @apply text-troo-secondary;
}
.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > *:not(ul):not(details):not(.menu-title)
  ):not(.active):hover,
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > details
      > summary:not(.menu-title)
  ):not(.active):hover {
  background-color: #0250ac15;
}
.menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),
.menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: inherit;
  padding: 15px 15px;
}
.collapse-plus .collapse-title:after {
  right: 0.5rem;
}
.select{
  height: auto;
}
.select:focus{
  outline-style: none
}
option{
  color: #171717;
}


@media (max-width:1024px){
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }

}
@media (max-width:768px){
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 26px;
    line-height: 36px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;   
  }
  h5 {
    font-size: 14px;
    
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }

}